import React from 'react';
import './PageRefSets.css';

function PageRefSets() {
	return (
		<div className="pageRefSets">
			<div className="pageRefSets__list">list</div>
			<div className="pageRefSets__detail">details</div>
		</div>
	);
}

export default PageRefSets;
