import React from 'react';
import './FilterSummary.css';

function FilterSummary({ count, type }) {
	return (
		<div className="filterSummary">
			<div className="filterSummary__count">
				( {count} {type} )
			</div>
			<div className="filterSummary__label">filter your results</div>
			<div className="filterSummary_arrow"></div>
		</div>
	);
}

export default FilterSummary;
