import React, { Component } from 'react';
import './ValueSetSidebar.css';
import { QueryVSContext } from '../contexts/QueryVSContext';
import StatusFilter from '../Filters/StatusFilter';
import FilterSummary from '../Filters/FilterSummary';
// import NameFilter from './Filters/NameFilter';
import TitleFilter from '../Filters/TitleFilter';

class ValueSetSidebar extends Component {
	static contextType = QueryVSContext;

	render() {
		const { bundle, getData } = this.context;

		return (
			<div className="valueSetSidebar">
				<div className="valueSetSidebar__filters">
					<FilterSummary count={bundle.total} type="Value Sets" />
					<StatusFilter />
					{/* <NameFilter /> */}
					<TitleFilter />
					<button onClick={getData}> Click Me </button>
				</div>
			</div>
		);
	}
}

export default ValueSetSidebar;
