import React, { Component, createContext } from 'react';

export const QueryVSContext = createContext();

class QueryVSContextProvider extends Component {
	state = {
		bundle: {},
		apiurl:
			'https://r4.ontoserver.csiro.au/fhir/ValueSet?_sort=title&_count=15',
		title: 'Abracadabra !!!!',
		filterName: '',
		filterTitle: '',
		filterStatus: '',
	};

	constructor(props) {
		super(props);

		this.getData();

		console.log(props);
	}

	getData = async () => {
		var qUrl = this.state.apiurl;
		var query = '';

		if (this.state.filterName !== '') {
			query = `&name:contains=${this.state.filterName}`;
		}

		if (this.state.filterTitle !== '') {
			query = query + `&title:contains=${this.state.filterTitle}`;
		}

		if (this.state.filterStatus !== '') {
			query = query + `&status=${this.state.filterStatus}`;
		}

		const response = await fetch(qUrl + query);
		const jsonData = await response.json();

		console.log(query);
		this.setState({ bundle: jsonData });
	};

	setFilterName = (name) => {
		this.setState({ filterName: name });
	};

	setFilterTitle = (title) => {
		this.setState({ filterTitle: title });
	};

	setFilterStatus = (status) => {
		this.setState({ filterStatus: status });
	};

	render() {
		return (
			<QueryVSContext.Provider
				value={{
					...this.state,
					getData: this.getData,
					setFilterName: this.setFilterName,
					setFilterTitle: this.setFilterTitle,
					setFilterStatus: this.setFilterStatus,
				}}
			>
				{this.props.children}
			</QueryVSContext.Provider>
		);
	}
}

export default QueryVSContextProvider;
