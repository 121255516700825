import React, { Component } from 'react';
// import { QueryVSContext } from './contexts/QueryVSContext';
import './Header.css';
import MenuBar from './MenuBar';
import SettingsIcon from '@material-ui/icons/Settings';

class Header extends Component {
	// static contextType = QueryVSContext;

	render() {
		// const { filterTitle } = this.context;
		// console.log(this.context);

		return (
			<div className="header">
				<div className="header__logo">
					<div className="header__left">
						<img
							src="http://clarotech.co.uk/wp-content/uploads/clarotech-small-footer.png"
							alt=""
						/>
					</div>
					{/* <div className="header__middle">{JSON.stringify(bundle)}</div> */}
					<div className="header__right">
						<SettingsIcon className="header__settings"></SettingsIcon>
					</div>
				</div>
				<MenuBar />
			</div>
		);
	}
}

export default Header;
