import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import './MenuBar.css';

class MenuBar extends Component {
	// static contextType = QueryVSContext;

	render() {
		// const { filterTitle } = this.context;
		console.log(this.props);

		return (
			<div className="menuBar">
				<ul className="menuBar__list">
					<NavLink className="menuBar__item" exact to="/">
						<HomeIcon className="menuBar__link" />
					</NavLink>
					<NavLink className="menuBar__item" to="/CodeSystem">
						<span className="menuBar__link">Code Systems</span>
					</NavLink>
					<NavLink to="/ValueSet" className="menuBar__item">
						<span className="menuBar__link">Value Sets</span>
					</NavLink>
					<NavLink to="/RefSets" exact className="menuBar__item">
						<span className="menuBar__link">RefSets</span>
					</NavLink>
					<NavLink to="/" exact className="menuBar__item">
						<span className="menuBar__link">Concept Maps</span>
					</NavLink>
					<NavLink to="/" exact className="menuBar__item">
						<span className="menuBar__link">Naming Systems</span>
					</NavLink>
				</ul>
			</div>
		);
	}
}

export default MenuBar;
