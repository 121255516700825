import React from 'react';
import ValueSetSidebar from './ValueSetSidebar';
import './PageValueSet.css';
import ValueSetBody from './ValueSetBody';
import QueryVSContextProvider from '../contexts/QueryVSContext';

function PageValueSet() {
	return (
		<div className="pageValueSet">
			<QueryVSContextProvider test="abc">
				<ValueSetSidebar />
				<ValueSetBody />
			</QueryVSContextProvider>
		</div>
	);
}

export default PageValueSet;
