import React, { Component } from 'react';
import { QueryCSContext } from '../contexts/QueryCSContext';
import './CodeSystemBody.css';
import CodeSystemItem from './CodeSystemItem';
// import ValueSetPage from './ValueSetPage';

class CodeSystemBody extends Component {
	static contextType = QueryCSContext;

	render() {
		const { bundle } = this.context;

		const items =
			bundle.entry == null ? this.emptyList() : this.createList(bundle);

		return (
			<div className="codeSystemBody">
				{/* <ValueSetPage /> */}
				{/* <hr className="codeSystemBody__rule" /> */}
				{items}
			</div>
		);
	}

	emptyList() {
		return (
			<div className="codeSystemBody__codeSystemEmpty">
				<div className="codeSystemBody__codeSystemEmptyText">
					No Code Systems match the filter criteria
				</div>
			</div>
		);
	}

	createList(bundle) {
		var items = bundle.entry.map((item) => (
			<CodeSystemItem
				className="codeSystemBody__codeSystemItem"
				item={item.resource}
				key={item.resource.id}
			></CodeSystemItem>
		));

		// items = '<ValueSetPage/>' + items;

		return items;
	}
}

export default CodeSystemBody;
