import React from 'react';
import './App.css';
import Header from './Header';

import PageValueSet from './ValueSet/PageValueSet';
import PageCodeSystem from './CodeSystem/PageCodeSystem';
import HomePage from './HomePage';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageRefSets from './RefSets/PageRefSets';

function App() {
	return (
		<Router>
			<div className="app">
				<Header />
				<Switch>
					<Route path="/CodeSystem" component={PageCodeSystem} />
					<Route path="/ValueSet" component={PageValueSet} />
					<Route path="/RefSets" component={PageRefSets} />
					<Route path="/" exact component={HomePage} />
				</Switch>
			</div>
		</Router>
	);
}

export default App;
