import React from 'react';
import moment from 'moment';
import './ValueSetItem.css';

function ValueSetItem({ item }) {
	var status_color = '';
	var pill_color = '';

	switch (item.status) {
		case 'active':
			status_color = 'status_green';
			pill_color = 'pill pill_green';
			break;
		case 'draft':
			status_color = 'status_amber';
			pill_color = 'pill pill_amber';
			break;
		default:
			status_color = 'status_grey';
			pill_color = 'pill pill_grey';
	}

	const div_style = 'valueSetItem ' + status_color;
	const date = moment(item.date);
	const versionText = item.version !== undefined ? `v${item.version}` : '';

	var tags = item.meta.tag.filter((x) => x.system === 'http://acme.org/tags/');
	var tagPills = tags.map((x) => (
		<span key={x.code} className="pill pill_blue">
			{x.code}
		</span>
	));

	return (
		<div className={div_style}>
			<div className="valueSetItem__left">
				<div className="valueSetItem__title">
					{item.title}{' '}
					<span className="valueSetItem__version">{versionText}</span>
				</div>
				<div className="valueSetItem__url">{item.url}</div>
				<div className="valueSetItem__tag">{tagPills}</div>
			</div>
			<div className="valueSetItem__right">
				<div className="valueSetItem__status">
					<span className={pill_color}>{item.status}</span>
				</div>
				<div className="valueSetItem__date">
					Updated : {date.format('DD MMM-YYYY')}
				</div>
			</div>
		</div>
	);
}

export default ValueSetItem;
