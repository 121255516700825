import React, { Component } from 'react';
import { QueryVSContext } from '../contexts/QueryVSContext';
import './ValueSetBody.css';
import ValueSetItem from './ValueSetItem';
import ValueSetPage from './ValueSetPage';

class ValueSetBody extends Component {
	static contextType = QueryVSContext;

	// constructor(props) {
	// 	super(props);

	// 	// const { bundle } = this.context;
	// }
	render() {
		const { bundle } = this.context;

		const items =
			bundle.entry == null ? this.emptyList() : this.createList(bundle);

		return (
			<div className="valueSetBody">
				<ValueSetPage />
				{/* <hr className="valueSetBody__rule" /> */}
				<div className="valueSetBody__list">{items}</div>
			</div>
		);
	}

	emptyList() {
		return (
			<div className="valueSetBody__valueSetEmpty">
				<div className="valueSetBody__valueSetEmptyText">
					No Value Sets match the filter criteria
				</div>
			</div>
		);
	}

	createList(bundle) {
		var items = bundle.entry.map((item) => (
			<ValueSetItem
				className="valueSetBody__valueSetItem"
				item={item.resource}
				key={item.resource.id}
			></ValueSetItem>
		));

		// items = '<ValueSetPage/>' + items;

		return items;
	}
}

export default ValueSetBody;
