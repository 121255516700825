import React from 'react';
import CodeSystemBody from './CodeSystemBody';
import CodeSystemSidebar from './CodeSystemSidebar';
import './PageCodeSystem.css';
import QueryCSContextProvider from '../contexts/QueryCSContext';

function PageCodeSystem() {
	return (
		<div className="pageCodeSystem">
			<QueryCSContextProvider>
				<CodeSystemSidebar />
				<CodeSystemBody />
			</QueryCSContextProvider>
		</div>
	);
}

export default PageCodeSystem;
