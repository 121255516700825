import React, { Component } from 'react';
import { QueryCSContext } from './../contexts/QueryCSContext';
import './CSStatusFilter.css';

class CSStatusFilter extends Component {
	static contextType = QueryCSContext;

	constructor(props) {
		super(props);
		this.state = {
			filterActive: false,
			filterDraft: false,
			filterRetired: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.checked;
		const name = target.name;

		this.setState({
			filterActive: false,
			filterDraft: false,
			filterRetired: false,
		});

		this.setState({
			[name]: value,
		});

		const { setFilterStatus } = this.context;
		setFilterStatus(name.toLowerCase().substring(6));

		console.log(name.toLowerCase().substring(6));
	}

	resetFilter(e) {
		e.preventDefault();

		this.setState({
			filterActive: false,
			filterDraft: false,
			filterRetired: false,
		});

		const { setFilterStatus } = this.context;
		setFilterStatus('');
	}
	render() {
		return (
			<div className="csStatusFilter">
				<p className="csStatusFilter__title">Status</p>
				<div className="csStatusFilter__options">
					<label className="csStatusFilter__label">
						<input
							type="checkbox"
							onChange={this.handleInputChange}
							checked={this.state.filterDraft}
							name="filterDraft"
						/>
						<span>Draft</span>
					</label>
					<label className="csStatusFilter__label">
						<input
							type="checkbox"
							onChange={this.handleInputChange}
							checked={this.state.filterActive}
							name="filterActive"
						/>
						<span>Active</span>
					</label>
					<label className="csStatusFilter__label">
						<input
							type="checkbox"
							onChange={this.handleInputChange}
							checked={this.state.filterRetired}
							name="filterRetired"
						/>
						<span>Retired</span>
					</label>
					<span
						onClick={this.resetFilter}
						className="csStatusFilter__reset"
						href="#"
					>
						Clear Filter
					</span>
				</div>
			</div>
		);
	}
}

export default CSStatusFilter;
