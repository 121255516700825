import React from 'react';

import './HomePage.css';

function HomePage() {
	return (
		<div className="home">
			<div className="home__body">
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>
					This is my hsdfdsfdsffdffome is my hsdfdsfdsffdffome is my
					hsdfdsfdsffdffome is my hsdfdsfdsffdffome is my hsdfdsfdsffdffome is
					my hsdfdsfdsffdffome is my hsdfdsfdsffdffome is my hsdfdsfdsffdffome
					is my hsdfdsfdsffdffome is my hsdfdsfdsffdffome is my
					hsdfdsfdsffdffome is my hsdfdsfdsffdffome
				</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>{' '}
				<h1>This is my home</h1> <h1>This is my home</h1>
			</div>
		</div>
	);
}

export default HomePage;
