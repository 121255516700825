import React, { Component } from 'react';
import { QueryVSContext } from './../contexts/QueryVSContext';
import './TitleFilter.css';

class TitleFilter extends Component {
	static contextType = QueryVSContext;

	constructor(props) {
		super(props);
		this.state = {
			filterTitle: '',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;

		this.setState({
			filterTitle: value,
		});

		const { setFilterTitle } = this.context;
		setFilterTitle(value);
	}

	resetFilter(e) {
		e.preventDefault();

		this.setState({
			filterTitle: '',
		});
		const { setFilterTitle } = this.context;
		setFilterTitle('');
	}
	render() {
		return (
			<div className="titleFilter">
				<p className="titleFilter__title">Title</p>
				<div className="titleFilter__options">
					<label className="titleFilter__label">
						<input
							type="text"
							onChange={this.handleInputChange}
							value={this.state.filterName}
							name="filterName"
						/>
					</label>
					<span
						onClick={this.resetFilter}
						className="titleFilter__reset"
						href="#"
					>
						Clear Filter
					</span>
				</div>
			</div>
		);
	}
}

export default TitleFilter;
