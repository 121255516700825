import React, { Component } from 'react';
import './CodeSystemSidebar.css';
import { QueryCSContext } from '../contexts/QueryCSContext';
import StatusFilter from '../Filters/StatusFilter';
import FilterSummary from '../Filters/FilterSummary';
// import NameFilter from './Filters/NameFilter';
import TitleFilter from '../Filters/TitleFilter';
import CodeSystemFilters from './CodeSystemFilters';
import CSStatusFilter from './CSStatusFilter';

class CodeSystemSidebar extends Component {
	static contextType = QueryCSContext;

	render() {
		const { bundle, getData } = this.context;

		return (
			<div className="codeSystemSidebar">
				<div className="codeSystemSidebar__filters">
					<FilterSummary count={bundle.total} type="Code Systems" />
					{/* <NameFilter /> */}
					{/* <TitleFilter />  */}
					<CSStatusFilter />
					<CodeSystemFilters />
					<button onClick={getData}> Click Me </button>
				</div>
			</div>
		);
	}
}

export default CodeSystemSidebar;
