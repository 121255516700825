import React, { Component } from 'react';
import { QueryVSContext } from '../contexts/QueryVSContext';
import './ValueSetPage.css';

class ValueSetPage extends Component {
	static contextType = QueryVSContext;

	render() {
		const { bundle } = this.context;

		return (
			<div className="valueSetPage">
				<div className="valueSetPage__left">
					<span className="valueSetPage__size">View 20 Per Page</span>
					{this.previousPage()}
					{this.currentPage()}
					{this.nextPage()}
				</div>

				<div className="valueSetPage__right">aa</div>
			</div>
		);
	}

	previousPage() {
		return <span className="valueSetPage__page">&lt;&lt;</span>;
	}

	currentPage() {
		return <span className="valueSetPage__pageCurent">X</span>;
	}

	nextPage() {
		return <span className="valueSetPage__page">&gt;&gt;</span>;
	}
}
export default ValueSetPage;
